import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { about, hero, heroText, mobileLogo, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="90vh"
          w="100%"
          boxShadow="1px 1px 3px rgba(0,0,0,.6)"
          image={`url(${about}) center / cover no-repeat`}
          column
          center
          textCenter
        >
          <CFView
            w="100%"
            pv="15px"
            column
            justifyCenter
            alignCenter
            bg="rgba(0,0,0,.8)"
          >
            <CFImage
              w="35%"
              maxWidth="350px"
              src={mobileLogo}
              alt="Jumak Ottawa hero text"
              zIndex={98}
            />
            <CFImage
              w="95%"
              maxWidth="350px"
              src={heroText}
              alt="Jumak Ottawa hero text"
              zIndex={98}
            />
            <CFView mv="10px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mobileLogo="10px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          w="100%"
          h="100vh"
          maxHeight="650px"
          maxWidth="1400px"
          zIndex={90}
          image={`url(${hero}) center / cover no-repeat`}
          column
          justifyBetween
          textCenter
        >
          <Header />
          <CFView column center h="600px">
            <CFImage
              w="70%"
              maxWidth="600px"
              src={heroText}
              alt="Jumak Ottawa hero text"
              zIndex={98}
            />
            <CFView mv="15px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mobileLogo="10px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
