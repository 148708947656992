export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1648745895/sollee/Group_609_5.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1648745730/sollee/Group_610_9.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1590261442/general/cflogo.png'
export const about = 'https://d24qx7sugd1e2d.cloudfront.net/about.jpg'
export const foodGallery =
  'https://d24qx7sugd1e2d.cloudfront.net/foodGallery.webp'
export const contactbg = 'https://d24qx7sugd1e2d.cloudfront.net/contactBg.webp'
export const hero = 'https://d24qx7sugd1e2d.cloudfront.net/hero.webp'
export const heroText = 'https://d24qx7sugd1e2d.cloudfront.net/heroText.webp'
export const logo = 'https://d24qx7sugd1e2d.cloudfront.net/logo.webp'
export const locationMap = 'https://d24qx7sugd1e2d.cloudfront.net/map.webp'
export const mobileAbout =
  'https://d24qx7sugd1e2d.cloudfront.net/mobileAbout.jpg'
export const mobileContactbg =
  'https://d24qx7sugd1e2d.cloudfront.net/mobileContactBg.webp'
export const mobileGallery =
  'https://d24qx7sugd1e2d.cloudfront.net/mobileGallery.webp'
export const mobileHero =
  'https://d24qx7sugd1e2d.cloudfront.net/mobileHero.webp'
export const mobileLogo =
  'https://d24qx7sugd1e2d.cloudfront.net/mobileLogo.webp'
export const mobileMap = 'https://d24qx7sugd1e2d.cloudfront.net/mobileMap.webp'
export const mobileHeroText =
  'https://d24qx7sugd1e2d.cloudfront.net/mobileText.webp'
export const mobilePromotion =
  'https://d24qx7sugd1e2d.cloudfront.net/mobilePromo.webp'
export const orderPickupButton =
  'https://d24qx7sugd1e2d.cloudfront.net/orderButton.webp'
export const promotions = 'https://d24qx7sugd1e2d.cloudfront.net/promo2.webp'
export const skipButton =
  'https://d24qx7sugd1e2d.cloudfront.net/skipButton.webp'
